import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material'
import React, { useEffect } from 'react'

import '@talentinc/gatsby-theme-ecom/../../../common-styles/braintree-overrides.css'
import successfulPaymentSVG from '../../images/success-payment.svg'
import { navigate } from 'gatsby'
import { useWindow } from '../../hooks/useWindow'

const SUCCESS_DELAY_TIME_IN_MS = 5000

export const SuccessfulPaymentPage = () => {
  const theme = useTheme()
  const windowObject = useWindow()

  useEffect(() => {
    if(!windowObject) return
    const url = new URL(windowObject.location.toString())
    const redirectUrl = url.searchParams.get('redirectUrl')

    setTimeout(() => {
      navigate(redirectUrl ? redirectUrl : '/')
    }, SUCCESS_DELAY_TIME_IN_MS)
  }, [windowObject])

  return (
    <Box
      sx={{
      height: '100%',
      minHeight: '60vh',
      maxWidth: 1086,
      width: '95%',
      margin: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',

      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        maxWidth: '500px',
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
        },
      }}
    >
      <img src={successfulPaymentSVG} />
      <Typography variant="h2">Successful Payment!</Typography>

      <Typography variant="body1">
        We're processing your dashboard. Please wait to proceed
      </Typography>

      <Button startIcon={<CircularProgress style={{'color': 'white'}} size={20}/>}>Loading...</Button>
    </Box>
  </Box>
  )
}
